import { useQuery } from '@tanstack/react-query';
import { getLocations } from '../api/location';

const LOCATIONS = 'LOCATIONS';

export function useLocations(locationCodes: string[]) {
  return useQuery(
    [LOCATIONS, locationCodes],
    async () => {
      return (await getLocations({ query: { code: locationCodes } })).result;
    },
    {
      enabled: locationCodes.length > 0,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
}
